//JAVASCRIPT REQUIRED FOR ENTIRE WEBSITE
let $form, $message, timeoutId;

function captchaSubmit(grecaptchaResponse) {
    // Considering jQuery.validate is being used as the validation mechanism
    if ($form.valid()) {
        $form.find('#g-recaptcha-response').length && $form.find('#g-recaptcha-response').remove();

        jQuery.ajax({
                type: "POST",
                url: $form.attr("action"),
                data: $form.serialize() + "&g-recaptcha-response=" + grecaptchaResponse
            })
            .done(function(response) {
                // Display response message to the user | add success class to the message wrapper
                $message.slideUp(400, () => {
                    $message
                        .removeClass("error")
                        .addClass("success")
                        .html(response)
                        .slideDown();
                });
                // Hide the message after 7 seconds
                timeoutId && clearTimeout(timeoutId);
                timeoutId = setTimeout(function() {
                    $message.slideUp(400, () => {
                        $message
                            .html("")
                            .removeClass("success");
                    });
                }, 7000);
                // Resetting reCaptcha (v2) if available
                grecaptcha && grecaptcha.reset();
                // Uncomment for Submiting Google Analytics

                // if (response.toLowerCase().indexOf('thank') > -1) {
                //     gtag('event', 'Form Submission - ' + $form[0].elements[$form[0].elements.length - 1].value, {
                //         'event_category': 'Form',
                //         'event_label': $form[0].elements[$form[0].elements.length - 1].value,
                //         'value': 1
                //     });
                // }
                // Reset the form input fields
                $form.trigger("reset");
            })
            .fail(function(error) {
                // Display error message to the user | add error class to the message wrapper
                console.log(error);
                $message.slideUp(400, () => {
                    $message
                        .removeClass("success")
                        .addClass("error-send-mail")
                        .text(response ? response : "Oops! An error occured")
                        .slideDown();
                });
            });
    } else {
        grecaptcha && grecaptcha.reset();
    }
}

jQuery(document).ready(function() {
    jQuery(document).ready(function(){
        jQuery('.navbar-light .navbar-toggler-icon').click(function(){
          jQuery(this).toggleClass('active');
        });
      });
    jQuery(document).on("submit", 'form', e => {
        e.preventDefault();
        $form = jQuery(e.target);
        $message = $form.find(".form-messages");
        grecaptcha.execute();
        return false;
    });

    // jQuery.validator.setDefaults({
    //     debug: true,
    //     success: "valid"
    // });


    // $('#contactForm').length > 0 && $('#contactForm').validate();
    // $('#rateRequest').length > 0 && $('#rateRequest').validate();
    // $('#hiringDriver').length > 0 && $('#hiringDriver').validate();
    if (!window.matchMedia)
        return;
    var current = jQuery('head > link[rel="icon"][media]');
    jQuery.each(current, function(i, icon) {
        var match = window.matchMedia(icon.media);
        function swap() {
            if (match.matches) {
                current.remove();
                current = jQuery(icon).appendTo('head');
            }
        }
        match.addListener(swap);
        swap();
    });
});